<div class="nav" *ngIf="newNav && (nav.sub_pages.length || nav.rootPath)">
  <div>
    <h3 class="no-margin-top">Help</h3>
    <ul>
      <li>
        <a
          [ngClass]="{ active: !activePath || activePath === nav.rootPath }"
          (click)="getPage(nav.rootPath)"
          [title]="getNameFromPath(nav.rootPath)"
          >{{ getNameFromPath(nav.rootPath) }}</a
        >
      </li>
      <ng-container
        *ngTemplateOutlet="recursiveMenu; context: { $implicit: nav.sub_pages }"
      >
      </ng-container>
    </ul>
  </div>
</div>
<div>
  <ng-content></ng-content>
</div>

<ng-template #recursiveMenu let-subPages>
  <ng-container *ngFor="let subPage of subPages">
    <li>
      <a
        (click)="getPage(subPage.path)"
        [ngClass]="{ active: subPage.path === activePath }"
        >{{ getNameFromPath(subPage.path) }}</a
      >
    </li>
    <ul *ngIf="subPage.sub_pages?.length">
      <ng-container
        *ngTemplateOutlet="
          recursiveMenu;
          context: { $implicit: subPage.sub_pages }
        "
      ></ng-container>
    </ul>
  </ng-container>
</ng-template>
