<mat-card
  [class.auto-height]="isExpanded"
  [@dsAutoHeightExpansion]="isExpanded ? 'expanded' : 'collapsed'"
  class="no-padding"
  [style.z-index]="zIndex"
>
  <div class="content-wrapper">
    <div class="content-container">
      <ng-content></ng-content>
      <div
        class="full-width blur-overlay print-hidden"
        (click)="setExpanded(!isExpanded)"
        [class.expanded]="isExpanded"
      ></div>
    </div>
  </div>
  <button
    mat-raised-button
    (click)="setExpanded(!isExpanded)"
    [style.z-index]="zIndex + 1"
    class="print-hidden"
  >
    <mat-icon [class.rotate]="isExpanded"> expand_more </mat-icon>
  </button>
</mat-card>
