import { Component, Input } from '@angular/core';
import { matExpansionAnimations } from '@angular/material/expansion';
import { dsAnimations } from '@design-system/cdk/animations';

@Component({
  selector: 'ds-expansion-panel',
  templateUrl: './absolute-expansion-panel.component.html',
  styleUrls: ['./absolute-expansion-panel.component.scss'],
  animations: [
    matExpansionAnimations.indicatorRotate,
    dsAnimations.autoHeightExpansion,
  ],
})
export class DsAbsoluteExpansionPanelComponent {
  @Input() zIndex: number;

  isExpanded = false;

  setExpanded(expanded: boolean) {
    this.isExpanded = expanded;
  }
}
