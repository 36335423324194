import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { dsConfig } from '@design-system/cdk/config';
import {
  debounceTime,
  distinctUntilChanged,
  Observable,
  ReplaySubject,
  startWith,
  Subject,
  takeUntil,
} from 'rxjs';
import { DsFilterV2DrawerComponent } from './filter-v2-drawer.component';

@Directive({ selector: '[dsFilterItem]' })
export class DsFilterItemDirective implements OnInit, OnDestroy {
  @Input() set dsFilterItem(val: AbstractControl) {
    if (val) {
      this.link(val);
    }
  }

  protected links: Set<AbstractControl> = new Set();
  destroy$ = new Subject<void>();
  private hasValue_ = new ReplaySubject<boolean>(1);
  hasValue$ = this.hasValue_.asObservable();
  private initial = true;

  get value(): any {
    const [control] = Array.from(this.links);
    return control?.value;
  }

  constructor(
    public templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    const view = this.viewContainer.createEmbeddedView(this.templateRef);
    this.renderer.addClass(view.rootNodes[0], 'ds-filter-item');
  }

  link(control: AbstractControl): void {
    control.valueChanges
      .pipe(startWith(this.value), takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          const v = control.getRawValue();
          const hasVal = v instanceof Date || !!v?.length;
          if (hasVal || !this.initial) {
            this.hasValue_.next(hasVal);
          }

          this.initial = false;

          this.links.forEach((link) =>
            link.patchValue(v, { emitEvent: false }),
          );
        },
      });
    this.links.add(control);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}

@Component({
  selector: 'ds-filter-v2',
  templateUrl: './filter-v2.component.html',
  styleUrls: ['./filter-v2.component.scss'],
})
export class DsFilterV2Component implements AfterViewInit, OnDestroy {
  listItems: TemplateRef<any>[];
  drawerItems: TemplateRef<any>[];
  @Input() showApplyButton: boolean;
  @Output() resetFilter = new EventEmitter<void>();
  @Output() apply = new EventEmitter<void>();
  badge = 0;

  @ContentChildren(DsFilterItemDirective) set items_(
    val: QueryList<DsFilterItemDirective>,
  ) {
    this.badge = 0;
    this.drawerItems = val?.map((x) => x.templateRef);
    val.forEach((x) =>
      x.hasValue$
        .pipe(
          distinctUntilChanged(),
          takeUntil(this.destroy$),
          takeUntil(x.destroy$),
        )
        .subscribe({
          next: (hasValue) => {
            if (hasValue) {
              this.badge++;
            } else if (this.badge > 0) {
              this.badge--;
            }
          },
        }),
    );
    this.setItemsViewMode();
  }
  @ViewChild('resetBtn') resetBtn: ElementRef;
  @ViewChild('applyBtn') applyBtn: ElementRef;
  @ViewChild('applyBtnHelper') applyBtnHelper: any;

  resizeObserver: ResizeObserver;
  containerWidth: number;
  showAllFilter: boolean;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    private bottomSheet: MatBottomSheet,
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const elem = document.querySelector('.main-holder');
      if (elem) {
        new Observable((subscriber) => {
          this.resizeObserver = new ResizeObserver((entries) => {
            subscriber.next(entries[0].contentRect.width);
          });

          this.resizeObserver.observe(elem, { box: 'border-box' });

          return () => {
            this.resizeObserver.unobserve(elem);
            this.resizeObserver.disconnect();
          };
        })
          .pipe(
            debounceTime(50),
            distinctUntilChanged(),
            takeUntil(this.destroy$),
          )
          .subscribe((width) => {
            if (width) {
              this.zone.run(() => {
                this.containerWidth = width as number;
                this.setItemsViewMode();
              });
            }
          });
      }
    });
  }

  setItemsViewMode() {
    let totalContentsWidth = 0;
    this.listItems = [];
    this.showAllFilter = this.drawerItems.length > 1;

    this.drawerItems.forEach((templateRef) => {
      let totalWidthWithItem: number =
        totalContentsWidth +
        (templateRef?.elementRef?.nativeElement?.nextElementSibling
          ?.offsetWidth ||
          templateRef?.elementRef?.nativeElement?.previousElementSibling
            ?.offsetWidth ||
          0) +
        dsConfig.spacing;

      //reset button might appear if not yet visible
      if (!this.resetBtn) {
        totalWidthWithItem += 50;
      }
      // apply button might appear if not yet visible
      if (this.showApplyButton && !this.applyBtn) {
        totalWidthWithItem +=
          this.applyBtnHelper?._elementRef.nativeElement.offsetWidth ||
          0 + dsConfig.spacing / 2;
      }

      if (this.containerWidth > totalWidthWithItem) {
        this.listItems.push(templateRef);
        totalContentsWidth = totalWidthWithItem;
      } else {
        return;
      }
    });
    this.cd.detectChanges();
  }

  openAllFilters(): void {
    const ref = this.bottomSheet.open(DsFilterV2DrawerComponent, {
      panelClass: 'filter-panel',
      data: {
        drawerItems: this.drawerItems,
        showApplyButton: this.showApplyButton,
      },
    });
    ref.instance.apply
      .pipe(takeUntil(this.destroy$))
      .subscribe({ next: () => this.apply.emit() });
    ref.instance.resetFilter
      .pipe(takeUntil(this.destroy$))
      .subscribe({ next: () => this.resetFilter.emit() });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
