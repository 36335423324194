import { Component, Input } from '@angular/core';
import { HelpPagesService, WikiPage } from '../shared';

@Component({
  selector: 'ds-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent {
  @Input() nav: {
    rootPath: string;
    sub_pages: WikiPage[];
  };
  @Input() newNav: boolean;
  activePath: string;

  constructor(private helpPagesService: HelpPagesService) {}

  getNameFromPath(path: string): string {
    const pieces = path.split('/');
    return pieces[pieces.length - 1];
  }

  getPage(path: string) {
    this.activePath = path;
    this.helpPagesService.navigate(path);
  }
}
