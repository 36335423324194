<div
  fxLayout="row"
  fxLayoutAlign="space-between baseline"
  [fxLayoutGap]="0.5 | dsSpacing"
>
  <div
    class="main-holder overflow-hidden"
    fxFlex="100%"
    fxLayout="row"
    [fxLayoutGap]="0.5 | dsSpacing"
    fxLayoutAlign="stretch baseline"
  >
    <ng-container *ngFor="let item of listItems">
      <ng-template [ngTemplateOutlet]="item"></ng-template>
    </ng-container>
  </div>

  <div
    fxFlex="auto"
    fxLayout="row"
    [fxLayoutGap]="0.5 | dsSpacing"
    fxLayoutAlign="start baseline"
    class="buttons"
  >
    <button
      #resetBtn
      *ngIf="listItems?.length"
      mat-icon-button
      [matTooltip]="'design_system.filter.clear_all' | translate"
      (click)="resetFilter.emit()"
      class="reset"
    >
      <mat-icon>clear</mat-icon>
    </button>
    <button
      (click)="openAllFilters()"
      mat-stroked-button
      color="accent"
      class="all"
      [matBadgeHidden]="!badge"
      [matBadgePosition]="'below before'"
      [matBadge]="badge"
      *ngIf="showAllFilter || !listItems?.length"
      ngClass.lt-md="margin"
      data-cy="open-filter-button"
    >
      <mat-icon icon-button>filter_alt</mat-icon>
      {{
        (listItems?.length
          ? 'design_system.filter.all_filters'
          : 'design_system.filter.filter'
        ) | translate
      }}
    </button>
    <button
      #applyBtn
      data-cy="filter-apply-btn"
      mat-raised-button
      color="primary"
      (click)="apply.emit()"
      *ngIf="showApplyButton && listItems?.length"
    >
      {{ 'design_system.filter.apply' | translate }}
    </button>
  </div>
</div>

<div class="helper">
  <ng-content></ng-content>
</div>

<div class="helper">
  <button #applyBtnHelper mat-raised-button>
    {{ 'design_system.filter.apply' | translate }}
  </button>
</div>
