import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { DsPageModule } from '@design-system/feature/page';
import { HelpPageComponent } from './help-page/help-page.component';
import { HelpPagesSidebarComponent } from './help-pages-sidebar/help-pages-sidebar.component';
import { NavComponent } from './nav/nav.component';

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild(
  [
    {
      path: '',
      component: HelpPageComponent,
      data: {
        sidebar: HelpPagesSidebarComponent,
      },
    },
  ],
);

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    DsSpacingModule,
    FlexLayoutModule,
    DsPageModule,
    routing,
    DsPlaceholderModule,
  ],
  declarations: [HelpPageComponent, HelpPagesSidebarComponent, NavComponent],
})
export class DSHelpPageModule {}
