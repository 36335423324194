<ds-page-sidebar *ngIf="nav$ | async as nav">
  <ds-page-sidebar-header>
    <mat-icon ds-page-sidebar-item-icon>
      help
    </mat-icon>
    <h4>HELP</h4>
  </ds-page-sidebar-header>
  <ds-page-sidebar-body>
    <nav *ngIf="nav.sub_pages?.length || nav.rootPath; else loading">
      <ds-page-sidebar-item
        (click)="getPage(nav.rootPath)"
        [ngClass]="{ active: !activePath }"
      >
        <mat-icon ds-page-sidebar-item-icon>
          home
        </mat-icon>
        {{ getNameFromPath(nav.rootPath) }}
      </ds-page-sidebar-item>
      <ng-container
        *ngTemplateOutlet="recursiveMenu; context: { $implicit: nav.sub_pages }"
      >
      </ng-container>
    </nav>
  </ds-page-sidebar-body>
</ds-page-sidebar>
<ng-template #recursiveMenu let-subPages>
  <ng-container *ngFor="let subPage of subPages">
    <div class="recursive-item-menu-holder">
      <ds-page-sidebar-item
        (click)="getPage(subPage.path)"
        [ngClass]="{ active: subPage.path === activePath }"
      >
        <mat-icon ds-page-sidebar-item-icon>
          description
        </mat-icon>
        {{ getNameFromPath(subPage.path) }}
      </ds-page-sidebar-item>
      <ng-container *ngIf="subPage.sub_pages?.length">
        <ng-container
          *ngTemplateOutlet="
            recursiveMenu;
            context: { $implicit: subPage.sub_pages }
          "
        ></ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>
<ng-template #loading
  ><mat-progress-bar mode="indeterminate"></mat-progress-bar
></ng-template>
