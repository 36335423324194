<strong fxLayout fxLayoutAlign="start center">
  <ng-container
    *ngFor="
      let breadcrumb of breadcrumbs;
      let first = first;
      let last = last;
      let i = index
    "
  >
    <span
      [fxHide.lt-md]="i !== breadcrumbs.length - 2"
      *pdLet="breadcrumb.label$ | async as label"
      fxLayout
      fxLayoutAlign="center center"
    >
      <ng-container *ngIf="!first"
        >&nbsp;<mat-icon fxHide.lt-md>chevron_right</mat-icon
        ><mat-icon fxLayoutAlign="center center" fxHide.gt-sm
          >chevron_left</mat-icon
        >&nbsp;</ng-container
      >
      <a
        class="no-underline"
        *ngIf="!last; else lastbreadcrumb"
        [routerLink]="['./' + breadcrumb.url]"
        data-test="breadcrumb"
      >
        <ng-container *ngIf="label; else labelLoading">
          {{
            breadcrumb?.supressTitleCase
              ? (label | translate)
              : (label | translate | titlecase)
          }}
        </ng-container>
        <ng-template #labelLoading>...</ng-template>
      </a>
      <ng-template #lastbreadcrumb>
        <ng-container *ngIf="label; else labelLoading"
          ><span data-test="breadcrumb">
            {{
              breadcrumb?.supressTitleCase
                ? (label | translate)
                : (label | translate | titlecase)
            }}
          </span>
        </ng-container>
        <ng-template #labelLoading>...</ng-template>
      </ng-template>
    </span>
  </ng-container>
</strong>
