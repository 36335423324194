export class EnvironmentBase {
  static palfinger_api_management = '';
  static palfinger_api_management_subs_key = '';
  static domainsForApiManagementSubsKey: string[];

  static oidc_clientid = '';
  static oidc_scope = '';
  static application_insights_key = '';
  static google_analytics_id = '';

  static LINKS: {
    SECURITY_TOKEN_SERVICE: string;
    POST_LOGOUT_REDIRECT_URI: string;
    PALDESK_BASEPATH: string;
    STATUS_PALDESK_PAGE: string;
  } = {
    SECURITY_TOKEN_SERVICE: '',
    POST_LOGOUT_REDIRECT_URI: '',
    PALDESK_BASEPATH: '',
    STATUS_PALDESK_PAGE: '',
  };

  static identityServerAuthConf: {
    whitelistedDomains: string[];
    whitelistedRoutes: string[];
    blacklistedRoutes: string[];
  } = {
    whitelistedDomains: [],
    whitelistedRoutes: [],
    blacklistedRoutes: [],
  };
}
