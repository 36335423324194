import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelpPagesService, WikiPage } from '../shared';

@Component({
  selector: 'ds-help-pages-sidebar',
  templateUrl: './help-pages-sidebar.component.html',
  styleUrls: ['./help-pages-sidebar.component.scss'],
})
export class HelpPagesSidebarComponent implements OnDestroy {
  activePath: string;

  nav$: Observable<{
    rootPath: string;
    sub_pages: WikiPage[];
  }>;

  private destroy$ = new Subject<void>();

  constructor(private helpPagesService: HelpPagesService) {
    this.nav$ = this.helpPagesService.nav.pipe(takeUntil(this.destroy$));
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getPage(path: string) {
    this.activePath = path;
    this.helpPagesService.navigate(path);
  }

  /**
   * Get a page name from it's path
   */
  getNameFromPath(path: string): string {
    const pieces = path.split('/');
    return pieces[pieces.length - 1];
  }
}
