<ds-app-wrapper
  [helpIconUrl]="'help'"
  [helpOpenSameWindow]="true"
  [hasSidebar]="isHelpPage"
  fastFeedbackAppId="ClaimOnSuppliers"
>
  <ds-breadcrumbs
    baseUrl="/"
    baseLabel="{{ 'cos.claim_on_suppliers' }}"
    fxFlex="0 0 auto"
    data-cy="cos_breadcrumb"
  >
  </ds-breadcrumbs>
  <div fxFlex="1 1 auto" class="flex-page-container">
    <router-outlet></router-outlet>
  </div>
</ds-app-wrapper>
