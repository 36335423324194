import {
  Component,
  ComponentFactoryResolver,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DsSidebarService } from '@design-system/feature/page';
import { marked } from 'marked';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  HelpPagesConfig,
  HelpPagesService,
  HELP_PAGES_CONFIG,
  PageService,
  VersionControlRecursionType,
  WikiPage,
} from '../shared/';

@Component({
  selector: 'ds-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss'],
})
export class HelpPageComponent implements OnInit, OnDestroy {
  convertedData: string;
  loading: boolean;

  nav$: Observable<{
    rootPath: string;
    sub_pages: WikiPage[];
  }>;

  private destroy$ = new Subject<void>();

  constructor(
    private helpPagesService: HelpPagesService,
    private wikiService: PageService,
    private sidebarService: DsSidebarService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    @Inject(HELP_PAGES_CONFIG) public help_pages_config: HelpPagesConfig,
  ) {
    if (!help_pages_config.newNav) {
      this.sidebarService.setContext(
        this.componentFactoryResolver,
        this.injector,
      );
    }

    this.helpPagesService.navigateLink.subscribe((path) => {
      if (path) this.getPage(path);
    });
  }

  ngOnInit() {
    this.nav$ = this.helpPagesService.nav.pipe(takeUntil(this.destroy$));
    this.getPage();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getPage(path?: string) {
    const md = marked.setOptions({});
    this.loading = true;
    this.wikiService
      .getPage(
        path ? path : this.help_pages_config.pagePath,
        this.help_pages_config.projectName || '',
        this.help_pages_config.wikiName || '',
        VersionControlRecursionType.Full,
        true,
      )
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((data) => {
        if (data && !path) {
          this.helpPagesService.setNavigation(
            this.help_pages_config.pagePath,
            data.page?.sub_pages || [],
          );
        }
        this.convertedData = md.parse(data.page?.content || '');
      });
  }
}
