import { Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WikiPage } from '.';

export interface HelpPagesConfig {
  pagePath: string;
  projectName: string;
  wikiName: string;
  newNav: boolean;
}

export const HELP_PAGES_CONFIG = new InjectionToken<HelpPagesConfig>(
  'HELP_PAGES_CONFIG',
);

@Injectable({
  providedIn: 'root',
})
export class HelpPagesService {
  nav = new BehaviorSubject<{
    rootPath: string;
    sub_pages: WikiPage[];
  }>({ rootPath: '', sub_pages: [] });

  navigateLink = new BehaviorSubject<string>('');

  setNavigation(rootPath: string, sub_pages: WikiPage[]): void {
    this.nav.next({ rootPath, sub_pages });
  }

  navigate(path: string): void {
    this.navigateLink.next(path);
  }
}
