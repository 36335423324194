<ds-nav [nav]="nav$ | async" [newNav]="help_pages_config.newNav">
  <mat-card>
    <mat-card-content *ngIf="!loading; else loadingTemplate">
      <div
        *ngIf="convertedData?.length; else placeholderTemplate"
        fxLayout="row"
        [fxLayoutGap]="1 | dsSpacing"
      >
        <div fxFlex="auto" [innerHTML]="convertedData"></div>
      </div>
    </mat-card-content>
  </mat-card>
</ds-nav>
<ng-template #loadingTemplate>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
<ng-template #placeholderTemplate>
  <ds-placeholder type="no_data"></ds-placeholder>
</ng-template>
