<div
  fxLayout="column"
  [fxLayoutGap]="1 | dsSpacing"
  class="sidebar-content scroll-container-vertical hidden-scollbar full-height"
  [class.mini]="!isExpanded && !media.isActive('xs')"
>
  <div class="header" fxLayout="column" [fxLayoutGap]="1 | dsSpacing">
    <a
      mat-button
      class="no-padding full-width"
      *ngIf="menu.backButtonUrl as backUrl; else logo"
      [routerLink]="[backUrl]"
      (click)="onItemClick()"
      data-cy="navigation-back-button"
    >
      <ng-container
        *ngTemplateOutlet="
          btn;
          context: {
            $implicit: {
              icon: 'arrow_back',
              title: 'general.back'
            }
          }
        "
      ></ng-container>
    </a>
    <ng-template #logo>
      <a
        class="inherit-color no-underline no-icon"
        [href]="paldeskUrl"
        [style.padding-left]="0.5 | dsSpacing"
      >
        <h2
          id="logo"
          class="palfinger-font uppercase left-text"
          [class.mini]="!isExpanded && !media.isActive('xs')"
        >
          P<span>aldesk</span>
        </h2>
      </a>
    </ng-template>

    <div></div>

    <div class="menu-header">
      <div class="no-padding" fxLayoutAlign="start center">
        <div
          [fxFlex]="2 | dsSpacing"
          class="icon-holder"
          fxLayoutAlign="center center"
        >
          <mat-icon *ngIf="!(menu.titleIcon | isPdIcon); else pdIcon">{{
            menu.titleIcon
          }}</mat-icon>
          <ng-template #pdIcon>
            <mat-icon
              *ngIf="menu.titleIcon | isPdIcon"
              fontSet="pd"
              [fontIcon]="menu.titleIcon"
            ></mat-icon>
          </ng-template>
        </div>
        <h4 class="cut-text">{{ menu.title | translate }}</h4>
      </div>
    </div>
  </div>

  <div *ngIf="!menuLoadingCount; else loadingMenu">
    <div *ngFor="let menuItem of menu.navigation">
      <div *ngIf="!menuItem.children?.length; else subMenu">
        <ng-container
          *ngTemplateOutlet="link; context: { $implicit: menuItem }"
        ></ng-container>
      </div>

      <ng-template #subMenu>
        <div
          class="relative-position"
          [routerLink]="[menuItem.routerLink]"
          [queryParamsHandling]="menuItem.queryParamsHandling"
          [dsDropdown]="menuItem.children"
          [dsDropdownTitle]="menuItem.title"
          [dsDropdownEnabled]="!isExpanded && !media.isActive('xs')"
        >
          <button
            mat-button
            class="no-padding full-width"
            routerLinkActive="active"
            (isActiveChange)="openItem($event, menuItem)"
            (click)="onItemClick(menuItem)"
            [ngClass]="{ 'info-text': menuItem.isHighlighted }"
            #rla="routerLinkActive"
            [attr.data-cy]="menuItem.title + '-navigate-item-button'"
          >
            <ng-container
              *ngTemplateOutlet="btn; context: { $implicit: menuItem }"
            ></ng-container>
          </button>
          <button
            class="expand-button"
            mat-icon-button
            *ngIf="
              menuItem.children?.length && (isExpanded || media.isActive('xs'))
            "
            (click)="$event.stopPropagation(); toggleOpened(menuItem)"
            [attr.data-cy]="menuItem.title + '-navigate-item-expand-button'"
          >
            <mat-icon>{{
              isOpened(menuItem) ? 'expand_more' : 'chevron_right'
            }}</mat-icon>
          </button>
        </div>
        <div
          class="expanding-panel"
          [@slideUpDown]
          *ngIf="isOpened(menuItem) && (isExpanded || media.isActive('xs'))"
        >
          <div
            class="child"
            *ngFor="let child of menuItem.children"
            [class.active]="rla.isActive"
          >
            <ng-container
              *ngTemplateOutlet="childTemplate; context: { $implicit: child }"
            >
            </ng-container>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <ng-template #loadingMenu>
    <div fxLayout="column">
      <div
        class="full-width"
        *ngFor="let load of menuLoadingCount | numberToArray"
        fxLayoutAlign="center"
        [style.height]="2 | dsSpacing"
      >
        <ds-loading-placeholder class="full-width" [height]="1.75 | dsSpacing">
        </ds-loading-placeholder>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #childTemplate let-child>
  <a
    *ngIf="child.externalLink; else routerLnk"
    mat-button
    class="no-padding"
    [href]="child.externalLink"
    [matBadge]="child.badge"
    [matBadgeHidden]="!child.badge"
    matBadgeColor="primary"
    matBadgeSize="small"
    matBadgePosition="after"
    [matBadgeOverlap]="false"
    [ngClass]="{ 'icon-badge': child.isBadgeIcon }"
    [attr.data-cy]="child.title + '-navigate-item-button'"
  >
    <span fxLayoutAlign="space-between center">
      <span class="container cut-text icon-spacer relative-position">{{
        child.title | translate
      }}</span>
      <mat-icon class="external">open_in_new</mat-icon>
    </span></a
  >
  <ng-template #routerLnk>
    <div
      class="relative-position"
      [routerLink]="[child.routerLink]"
      [queryParamsHandling]="child.queryParamsHandling"
    >
      <button
        mat-button
        class="no-padding full-width"
        routerLinkActive="active"
        (isActiveChange)="openItem($event, child)"
        (click)="onItemClick(child)"
        #rla="routerLinkActive"
        [attr.data-cy]="child.title + '-navigate-item-button'"
      >
        <div fxLayout>
          <span class="container relative-position cut-text">{{
            child.title | translate
          }}</span>
          <div class="badge relative-position" *ngIf="child.badge">
            {{ child.badge }}
          </div>
        </div>
      </button>
      <button
        class="expand-button"
        mat-icon-button
        *ngIf="child.children?.length && (isExpanded || media.isActive('xs'))"
        (click)="$event.stopPropagation(); toggleOpened(child)"
        [attr.data-cy]="child.title + '-navigate-item-expand-button'"
      >
        <mat-icon>{{
          isOpened(child) ? 'expand_more' : 'chevron_right'
        }}</mat-icon>
      </button>
    </div>
    <div
      class="expanding-panel"
      [@slideUpDown]
      *ngIf="isOpened(child) && (isExpanded || media.isActive('xs'))"
    >
      <div
        class="child"
        *ngFor="let child of child.children"
        [class.active]="rla.isActive"
      >
        <ng-container
          *ngTemplateOutlet="childTemplate; context: { $implicit: child }"
        >
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-template>

<!-- Show <a> or <button> depending on if this is routerLink or externalLink-->
<ng-template #link let-item>
  <button
    mat-button
    class="no-padding full-width"
    [routerLink]="[item.routerLink]"
    [queryParamsHandling]="item.queryParamsHandling"
    (click)="onItemClick(item)"
    routerLinkActive="active"
    (isActiveChange)="openItem($event, item)"
    #rla="routerLinkActive"
    *ngIf="item.routerLink; else external"
    [ngClass]="{ 'info-text': item.isHighlighted }"
    [attr.data-cy]="item.title + '-navigate-item-button'"
  >
    <ng-container
      *ngTemplateOutlet="btn; context: { $implicit: item }"
    ></ng-container>
  </button>
  <ng-template #external>
    <a
      mat-button
      [href]="item.externalLink"
      target="_blank"
      (click)="onItemClick(item); (!!item.externalLink)"
      [ngClass]="{ 'info-text': item.isHighlighted }"
      [attr.data-cy]="item.title + '-navigate-item-button'"
    >
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <ng-container
          *ngTemplateOutlet="btn; context: { $implicit: item }"
        ></ng-container>
        <mat-icon
          *ngIf="!!item.externalLink"
          [fxFlex]="1.5 | dsSpacing"
          class="external"
          >open_in_new</mat-icon
        >
      </div>
    </a>
  </ng-template>
</ng-template>

<ng-template #btn let-menuItem>
  <div fxLayout="row">
    <div
      [fxFlex]="2 | dsSpacing"
      fxLayoutAlign="center center"
      *ngIf="menuItem.icon || menuItem.equipmentIcon"
    >
      <ng-container *ngIf="menuItem.icon as icon">
        <mat-icon
          *ngIf="!(icon | isPdIcon); else pdIcon"
          [matTooltip]="menuItem.title | translate"
          [matTooltipDisabled]="isExpanded || menuItem.children?.length"
          [matTooltipPosition]="'right'"
          [matBadge]="menuItem.badge"
          [matBadgeHidden]="!menuItem.badge"
          matBadgeColor="primary"
          matBadgeSize="small"
          [ngClass]="{ 'icon-badge': menuItem.isBadgeIcon }"
          >{{ icon }}</mat-icon
        >
        <ng-template #pdIcon>
          <mat-icon
            *ngIf="icon | isPdIcon"
            fontSet="pd"
            [fontIcon]="icon"
            [matTooltip]="menuItem.title | translate"
            [matTooltipDisabled]="isExpanded || menuItem.children?.length"
            [matTooltipPosition]="'right'"
            [matBadge]="menuItem.badge"
            [matBadgeHidden]="!menuItem.badge"
            matBadgeColor="primary"
            matBadgeSize="small"
            [ngClass]="{ 'icon-badge': menuItem.isBadgeIcon }"
          ></mat-icon
        ></ng-template>
      </ng-container>

      <ds-equipment-icon
        *ngIf="menuItem.equipmentIcon as icon"
        [matTooltip]="menuItem.title | translate"
        [matTooltipDisabled]="isExpanded || menuItem.children?.length"
        [matTooltipPosition]="'right'"
        [matBadge]="menuItem.badge"
        [matBadgeHidden]="!menuItem.badge"
        matBadgeColor="primary"
        matBadgeSize="small"
        [type]="icon"
        [ngClass]="{ 'icon-badge': menuItem.isBadgeIcon }"
      ></ds-equipment-icon>
    </div>
    <div
      class="cut-text icon-spacer"
      fxFlex="0 1 auto"
      [class.hide-title]="!isExpanded"
    >
      {{ menuItem.title | translate }}
    </div>
  </div>
</ng-template>
